import PrimaryButton from "@components/buttons/PrimaryButton"
import {useNavigate} from "react-router"

export default function ChatWaitlist() {
  const navigate = useNavigate()
  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: "center",
        maxWidth: "600px",
        margin: "auto",
        alignItems: "center",
        padding: "2rem 2rem",
        boxSizing: "border-box",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
          padding: "2rem 2rem",
          boxSizing: "border-box",
          height: "100%",
        }}
      >
        <h1 className="page-title" style={{textAlign: "center"}}>
          Thanks!
        </h1>
        <p> You have been added to the waitlist! </p>
        <PrimaryButton
          buttonText={"Back to dashboard"}
          onClick={() => navigate("/app/dashboard")}
          borderRadius="6px"
        />
      </section>
    </main>
  )
}
