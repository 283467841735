import {signOut} from "firebase/auth"
import {auth} from "../../firebase"
import {useAuth} from "../../contexts/AuthContext"
import {Navigate, useMatch, useNavigate} from "react-router"
import DashboardIcon from "../icons/DashboardIcon"
import AddTransferIcon from "../icons/AddTranferIcon"
import CloseIcon from "../icons/CloseIcon"
import BudgetIcon from "../icons/BudgetIcon"
import TransactionIcon from "../icons/TransactionIcon"
import {useResponsive} from "../../contexts/ResponsiveContext"
import AddIcon from "../icons/AddIcon"
import SettingsIcon from "../icons/SettingsIcon"
import {Bar} from "react-chartjs-2"
import KpiIcon from "../icons/KpiIcon"
import VoteIcon from "../icons/VoteIcon"
import HomeIcon from "../icons/HomeIcon"
import {usePopup} from "../../contexts/PopupContext"
import PrimaryButton from "../buttons/PrimaryButton"
import {ClosePopupButton} from "../popups/SimplePopup"
import SecondaryButton from "../buttons/SecondaryButton"
import FlagIcon from "../icons/FlagIcon"

const LogOut = ({text, icon}) => {
  const {setCurrentUser} = useAuth()
  const {isPhone} = useResponsive()
  const navigate = useNavigate()
  const {showPopup, hidePopup} = usePopup()

  const showLogoutConfirmationPopup = (e) => {
    showPopup({content: <ConfirmLogoutPopup onLogout={handleLogout} />})
  }

  const handleLogout = async (e) => {
    signOut(auth)
    setCurrentUser(null)
    navigate("/")
    hidePopup()
  }

  return (
    <button onClick={() => showLogoutConfirmationPopup()} className="log-out">
      {icon && <img style={{width: "26px"}} src={icon} />}
      {!(isPhone && <p>{text}</p>)}
    </button>
  )
}

const ConfirmLogoutPopup = ({onLogout}) => {
  const {hidePopup} = usePopup()
  return (
    <div>
      <div className="popup-header">
        <h2>Are you sure?</h2>
        <ClosePopupButton />
      </div>
      <p>
        You are about to log out of Finanzia, but you can always login again
      </p>

      <div style={{display: "flex", justifyContent: "center", gap: "20px"}}>
        <SecondaryButton
          buttonText={"No, go back"}
          borderColor="var(--light-gray"
          onClick={() => hidePopup()}
        />
        <PrimaryButton
          buttonText={"Yes, log out"}
          onClick={onLogout}
          borderWidth={"2px"}
        />
      </div>
    </div>
  )
}

const LogIn = ({text, icon}) => {
  const {setCurrentUser} = useAuth()
  const navigate = useNavigate()

  return (
    <button
      onClick={() => {
        navigate("/cuenta/iniciar-sesion")
      }}
      className="log-out"
    >
      {icon && <img style={{width: "26px", paddingLeft: "4px"}} src={icon} />}
      <p>{text}</p>
    </button>
  )
}

const MenuItem = ({
  iconName,
  text,
  activeColor,
  color,
  onClick,
  toLink = "/",
  iconWidth = "24px",
}) => {
  const {isPhone} = useResponsive()

  const match = useMatch({
    path: toLink,
    end: true,
  })
  let icon
  switch (iconName) {
    case "dashboard":
      icon = (
        <HomeIcon color={match ? "#02dca2" : "#334050"} width={iconWidth} />
      )
      break
    case "add_transaction":
      icon = <AddIcon color={"#02dca2"} width={"48px"} />
      break

    case "close":
      icon = <CloseIcon color={"#334050"} width={"36px"} isFilled={true} />
      break
    case "transactions":
      icon = (
        <TransactionIcon
          color={match ? "#02dca2" : "#334050"}
          width={iconWidth}
        />
      )
      break
    case "settings":
      icon = (
        <SettingsIcon color={match ? "#02dca2" : "#334050"} width={iconWidth} />
      )
      break

    case "kpi":
      icon = <KpiIcon color={match ? "#02dca2" : "#334050"} width={iconWidth} />
      break

    case "flag":
      icon = (
        <FlagIcon color={match ? "#02dca2" : "#334050"} width={iconWidth} />
      )
      break

    case "vote":
      icon = (
        <VoteIcon color={match ? "#02dca2" : "#334050"} width={iconWidth} />
      )

      break
    case "budgets":
      icon = <BudgetIcon color={match ? "#02dca2" : "#334050"} />
      break
    default:
      break
  }

  const menuClass = `menu-item ${match ? "isActive" : ""}`
  return (
    <div
      className={menuClass}
      style={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {icon && icon}
      {!isPhone && <p>{text}</p>}
    </div>
  )
}

function MenuLink({text = null, children, onClick, toLink = "/"}) {
  const match = useMatch({path: toLink, end: true})
  if (text) {
    return (
      <div onClick={onClick} className={`menu-link ${match ? "isActive" : ""}`}>
        <p>{text}</p>
      </div>
    )
  }

  return (
    <div onClick={onClick} className={`menu-link ${match ? "isActive" : ""}`}>
      {children}
    </div>
  )
}

export {LogOut, MenuItem, LogIn, MenuLink}
