import {AddFeatureFlag, FeatureFlagsTable} from "../../components/featureFlags"
import PageHeader from "../../components/pages/PageHeader"
import {useFeatureFlags, useFlags} from "../../domain/feature-flags"

export default function FeatureFlagsPage() {
  const {
    featureFlags,
    getFeatureFlags,
    updateFeatureFlag,
    deleteFeatureFlag,
    sendFeatureFlag,
  } = useFeatureFlags()

  return (
    <main className="ffs-page">
      <PageHeader
        title={"Feature flags"}
        actions={<AddFeatureFlag addFeatureFlag={sendFeatureFlag} />}
        rightSideActions={null}
        hasMonthSelector={false}
      />
      <FeatureFlagsTable
        featureFlags={featureFlags}
        getFeatureFlags={getFeatureFlags}
        updateFeatureFlag={updateFeatureFlag}
        deleteFeatureFlag={deleteFeatureFlag}
      />
    </main>
  )
}
