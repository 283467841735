import styled from "styled-components"
import {ClosePopupButton} from "../popups/SimplePopup"
import {TextInput} from "../inputs/TextInput"
import {useRef, useState} from "react"
import {FormRow, SubmitButton} from "../forms/FormUtils"
import SelectInput from "../inputs/SelectInput"
import {FeatureFlag, useFeatureFlags} from "../../domain/feature-flags"
import {usePopup} from "../../contexts/PopupContext"

export function CreateFeatureFlagForm({
  addFeatureFlag,
}: {
  addFeatureFlag: (featureFlag: FeatureFlag) => void
}) {
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [userSegment, setUserSegment] = useState("")
  const [isActive, setIsActive] = useState(true)

  const {hidePopup} = usePopup()

  const handleSubmit = async () => {
    const featureFlag: FeatureFlag = {
      name: name,
      description: description,
      userSegment: userSegment,
      isActive: isActive,
    }

    await addFeatureFlag(featureFlag)

    setName("")
    setDescription("")
    setUserSegment("")
    setIsActive(true)
    hidePopup()
  }

  return (
    <FormWrapper>
      <FormHeader>
        <h2>Create feature flag</h2>
        <ClosePopupButton icon={null} text={null} />
      </FormHeader>
      <FormContainer>
        <TextInput
          inputId={"feature_flag_name"}
          inputName={"Feature flag name"}
          inputPlaceholder={"Name"}
          additionalClasses={"input-container"}
          textAlign="center"
          onChange={(e: any) => {
            setName(e.target.value)
          }}
          inputLabel={null}
          inputValue={null}
        />

        <TextInput
          inputId={"feature_flag_description"}
          inputName={"Feature flag description"}
          inputPlaceholder={"Description"}
          additionalClasses={"input-container"}
          textAlign="center"
          onChange={(e: any) => {
            setDescription(e.target.value)
          }}
          inputLabel={null}
          inputValue={null}
        />

        <FormRow>
          <SelectInput
            inputID={"feature_flag_user_segment"}
            inputName={"User segment"}
            onChange={(e: any) => {
              setUserSegment(e.target.value)
            }}
            defaultValue={"admin"}
            options={[
              {name: "Admin", value: "admin"},
              {name: "Betatester", value: "betatester"},
              {name: "Paying user", value: "paying_user"},
              {name: "Free user", value: "free_user"},
              {name: "All", value: "all"},
            ]}
          />

          <SelectInput
            inputID={"feature_flag_active"}
            inputName={"Active"}
            onChange={(e: any) => {
              setIsActive(e.target.value)
            }}
            defaultValue={true}
            options={[
              {name: "Active", value: true},
              {name: "Inactive", value: false},
            ]}
          />
        </FormRow>
        <SubmitButton
          buttonText={"Create feature flag"}
          buttonType="primary"
          onSend={handleSubmit}
          transType={"INCOME"}
          isDisabled={false}
        />
      </FormContainer>
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`
