import styled from "styled-components"
import {FeatureFlag, useFeatureFlags} from "../../domain/feature-flags"
import {useEffect, useState} from "react"
import {Switch, Select, MenuItem} from "@mui/material"
import TrashIcon from "../icons/TrashIcon"
import {usePopup} from "../../contexts/PopupContext"
import {DeleteFeatureFlag} from "./DeleteFeatureFlag"

export function FeatureFlagsTable({
  featureFlags,
  getFeatureFlags,
  updateFeatureFlag,
  deleteFeatureFlag,
}: {
  featureFlags: FeatureFlag[]
  getFeatureFlags: () => void
  updateFeatureFlag: (featureFlag: FeatureFlag) => void
  deleteFeatureFlag: (featureFlag: FeatureFlag) => void
}) {
  useEffect(() => {
    getFeatureFlags()
  }, [])

  return (
    <section
      className="feature-flags-table-container"
      style={{marginTop: "1.4rem"}}
    >
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>User segment</th>
            <th>Created at</th>
            <th>Updated at</th>
            <th>Is active?</th>
            <th>Actions</th>
          </tr>
        </thead>
        <TableBody>
          {featureFlags.map((featureFlag) => (
            <FeatureFlagRow
              key={featureFlag.name + featureFlag.updatedAt}
              featureFlag={featureFlag}
              updateFeatureFlag={updateFeatureFlag}
              deleteFeatureFlag={deleteFeatureFlag}
            />
          ))}
        </TableBody>
      </Table>
    </section>
  )
}

export function FeatureFlagRow({
  featureFlag,
  updateFeatureFlag,
  deleteFeatureFlag,
}: {
  featureFlag: FeatureFlag
  updateFeatureFlag: (featureFlag: FeatureFlag) => void
  deleteFeatureFlag: (featureFlag: FeatureFlag) => void
}) {
  const {showPopup} = usePopup()

  const handleToggleChange = async (e: any) => {
    const isActive = e.target.checked
    const updatedFeatureFlag = {...featureFlag, isActive}
    await updateFeatureFlag(updatedFeatureFlag)
  }

  const handleUserSegmentChange = async (e: any) => {
    const userSegment = e.target.value
    const updatedFeatureFlag = {...featureFlag, userSegment}
    await updateFeatureFlag(updatedFeatureFlag)
  }

  const createdAt = featureFlag.createdAt
    ? new Date(featureFlag.createdAt).toLocaleString()
    : ""
  const updatedAt = featureFlag.updatedAt
    ? new Date(featureFlag.updatedAt).toLocaleString()
    : ""

  return (
    <tr>
      <td>{featureFlag.name}</td>
      <td>{featureFlag.description}</td>
      <td>
        <Select
          value={featureFlag.userSegment}
          onChange={handleUserSegmentChange}
          style={{minWidth: "120px", border: "none"}}
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="betatester">Betatester</MenuItem>
          <MenuItem value="paying_user">Paying user</MenuItem>
          <MenuItem value="free_user">Free user</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </Select>
      </td>
      <td>{createdAt}</td>
      <td>{updatedAt}</td>
      <td>
        <Switch
          checked={featureFlag.isActive}
          onChange={handleToggleChange}
          color="success"
        />
      </td>
      <td>
        <span
          onClick={() => {
            showPopup({
              content: (
                <DeleteFeatureFlag
                  featureFlag={featureFlag}
                  deleteFunction={deleteFeatureFlag}
                />
              ),
            })
          }}
          style={{cursor: "pointer"}}
        >
          <TrashIcon color={"var(--gray)"} width="24px" height={null} />
        </span>
      </td>
    </tr>
  )
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 100%;
  max-height: 800px;
  border-spacing: 0;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 20px;
  thead {
    background-color: var(--gray);
    color: var(--white);

    tr {
      position: sticky;

      th {
        padding: 1rem 1.4rem;
        text-align: left;
      }

      th:first-child {
        border-top-left-radius: 20px;
      }

      th:last-child {
        border-top-right-radius: 20px;
      }
    }
  }
`

const TableBody = styled.tbody`
  tr:nth-child(even) {
    background-color: var(--background-light);
  }

  td {
    padding: 1rem 1.4rem;
    text-align: left;
  }
`
