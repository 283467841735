import {usePopup} from "../../contexts/PopupContext"
import {FeatureFlag} from "../../domain/feature-flags"
import AddIcon from "../icons/AddIcon"
import {CreateFeatureFlagForm} from "./CreateFeatureFlagForm"

export function AddFeatureFlag({
  addFeatureFlag,
}: {
  addFeatureFlag: (featureFlag: FeatureFlag) => void
}) {
  const {showPopup} = usePopup()

  const handleClick = () => {
    showPopup({
      content: <CreateFeatureFlagForm addFeatureFlag={addFeatureFlag} />,
    })
  }

  return (
    <span onClick={handleClick} style={{cursor: "pointer"}}>
      <AddIcon color={"#334050"} width={"30px"} height={null} />
    </span>
  )
}
