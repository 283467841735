import {useState} from "react"
import {apiClient} from "../../infraestructure/api/client"
import {FeatureFlag} from "./models"
import {useQuery} from "react-query"

interface ValidationOutcome {
  isValid: boolean
  errorMessage?: string
}

export function useFeatureFlags() {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const sendFeatureFlag = async (featureFlag: FeatureFlag) => {
    setIsLoading(true)
    try {
      console.log("Sending feature flag", featureFlag)
      const reponse = await apiClient.post("admin/feature-flags", featureFlag)
      return reponse
    } catch (error: any) {
      console.log("Error sending feature flag", error)
      setError(error)
    } finally {
      setIsLoading(false)
      getFeatureFlags()
    }
  }

  const getFeatureFlags = async () => {
    setIsLoading(true)
    try {
      const reponse: any = await apiClient.get("admin/feature-flags")

      const featureFlags: FeatureFlag[] = toFeatureFlags(reponse)

      setFeatureFlags(featureFlags)

      return featureFlags
    } catch (error: any) {
      console.log("Error getting feature flags", error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const updateFeatureFlag = async (featureFlag: FeatureFlag) => {
    setIsLoading(true)
    try {
      const reponse = await apiClient.put(
        `admin/feature-flags/${featureFlag.name}`,
        featureFlag
      )
      return reponse
    } catch (error: any) {
      console.log("Error updating feature flag", error)
      setError(error)
    } finally {
      setIsLoading(false)
      getFeatureFlags()
    }
  }

  const deleteFeatureFlag = async (featureFlag: FeatureFlag) => {
    setIsLoading(true)
    try {
      const reponse = await apiClient.delete(
        `admin/feature-flags/${featureFlag.name}`
      )
      return reponse
    } catch (error: any) {
      console.log("Error deleting feature flag", error)
      setError(error)
    } finally {
      setIsLoading(false)
      getFeatureFlags()
    }
  }

  const validateFeatureFlag = (featureFlag: FeatureFlag): ValidationOutcome => {
    if (!featureFlag.name || featureFlag.name === "") {
      return {
        isValid: false,
        errorMessage: "Name is required",
      }
    }

    const regex = /^[a-z_]+$/
    if (!regex.test(featureFlag.name)) {
      return {
        isValid: false,
        errorMessage: "Only lowercase alphabetic and _ characters are allowed",
      }
    }

    return {
      isValid: true,
    }
  }

  const toFeatureFlags = (featureFlags: any[]): FeatureFlag[] => {
    return featureFlags.map((featureFlag: any) => {
      return {
        name: featureFlag.name,
        description: featureFlag.description,
        userSegment: featureFlag.user_segment,
        isActive: featureFlag.is_active,
        createdAt: featureFlag.created_at,
        updatedAt: featureFlag.updated_at,
      }
    })
  }

  return {
    sendFeatureFlag,
    getFeatureFlags,
    updateFeatureFlag,
    deleteFeatureFlag,
    featureFlags,
    isLoading,
    error,
  }
}

const fetchFeatureFlags = async () => {
  const response: any = await apiClient.get("admin/feature-flags")
  return toFeatureFlags(response)
}

export const useFlags = () => {
  const {
    data: featureFlags,
    isLoading,
    error,
  } = useQuery("featureFlags", fetchFeatureFlags, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  const featureFlagApplies = (name: string, user: Record<string, any>) => {
    const userSegment = user.userType

    if (!featureFlags) {
      return false
    }

    const featureFlag = featureFlags.find(
      (featureFlag) => featureFlag.name === name
    )

    if (!featureFlag) {
      return false
    }

    if (!featureFlag.isActive) {
      return false
    }

    if (featureFlag.userSegment === "all") {
      return true
    }
  }

  return {
    featureFlags,
    isLoading,
    error,
    applies: featureFlagApplies,
  }
}

const toFeatureFlags = (featureFlags: any[]): FeatureFlag[] => {
  return featureFlags.map((featureFlag: any) => {
    return {
      name: featureFlag.name,
      description: featureFlag.description,
      userSegment: featureFlag.user_segment,
      isActive: featureFlag.is_active,
      createdAt: featureFlag.created_at,
      updatedAt: featureFlag.updated_at,
    }
  })
}
