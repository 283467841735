import {usePopup} from "../../contexts/PopupContext"
import {FeatureFlag} from "../../domain/feature-flags"
import PrimaryButton from "../buttons/PrimaryButton"
import SecondaryButton from "../buttons/SecondaryButton"
import {ClosePopupButton} from "../popups/SimplePopup"

export function DeleteFeatureFlag({
  featureFlag,
  deleteFunction,
}: {
  featureFlag: FeatureFlag
  deleteFunction: (featureFlag: FeatureFlag) => void
}) {
  const {hidePopup} = usePopup()

  const handleDelete = async () => {
    await deleteFunction(featureFlag)
    hidePopup()
  }
  return (
    <section>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <h2>Are you sure?</h2>
        <ClosePopupButton text={null} />
      </div>
      <p>
        This will delete the feature flag <strong>{featureFlag.name}</strong>.
        This means that if this feature is being used somewhere in the code, you
        might break it. Plase check this feature flag is not in use.
      </p>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "stretch",
          marginTop: "1rem",
        }}
      >
        <SecondaryButton
          buttonText={"No, go back"}
          onClick={hidePopup}
          borderColor="var(--gray)"
        />
        <PrimaryButton
          buttonText={"Yes, delete!"}
          onClick={handleDelete}
          borderWidth={"2px"}
        />
      </div>
    </section>
  )
}
